import React from 'react'
import {
    AppleIcon,
    GooglePlayIcon,
    InsIcon,
    LinkIcon,
    TomoLogoIcon,
    TwIcon,
    YoutubeIcon
} from '../components/icon/icon-min'

export default function Courtsidecrypto() {
    return (
        <div
            className={
                'pt-4 pb-10 text-tc1 dark:text-tc1-dark dark:bg-tc1 min-h-screen'
            }
        >
            <div className={'flex flex-col px-4 items-center gap-8'}>
                <div className={'flex justify-between w-full'}>
                    <a
                        href={'https://id.tomo.inc/courtsidecrypto'}
                        target={'_blank'}
                        className={
                            'flex items-center gap-1 rounded-full border border-e3 px-3 py-1.5'
                        }
                        rel="noreferrer"
                    >
                        <LinkIcon/>
                        <div>Our Tomo ID</div>
                    </a>

                    <TomoLogoIcon/>
                </div>
                <div className={'px-8 dark:invert'}>
                    <img src={'./images/courtsidecrypto/banner.png'} alt={'banner'}/>
                </div>
                <div className={'flex w-full flex-col items-center gap-4'}>
                    <div className={'flex w-full flex-col items-center gap-2'}>
                        <div className={'text-xl font-extrabold uppercase'}>
                            STAY TUNED FOR NEXT EPISODE
                        </div>
                    </div>

                    <a
                        className={
                            'bg-primary w-full rounded-lg py-3 px-4 text-white font-semibold text-center flex items-center justify-center gap-[8px] text-[16px]'
                        }
                        href={'https://www.youtube.com/@CourtsideCrypto/videos'}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_8392_18293)">
                                <path
                                    d="M10.0013 18.3334C14.6037 18.3334 18.3346 14.6025 18.3346 10.0001C18.3346 5.39771 14.6037 1.66675 10.0013 1.66675C5.39893 1.66675 1.66797 5.39771 1.66797 10.0001C1.66797 14.6025 5.39893 18.3334 10.0013 18.3334Z"
                                    fill="white" stroke="white" stroke-width="1.66667" stroke-linejoin="round"/>
                                <path
                                    d="M8.33594 10V7.11328L10.8359 8.55666L13.3359 10L10.8359 11.4434L8.33594 12.8868V10Z"
                                    fill="#F21F7F" stroke="#F21F7F" stroke-width="1.66667" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_8392_18293">
                                    <rect width="20" height="20" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <div>Watch All Episodes Here</div>
                    </a>
                </div>
            </div>
            <div className={'w-full pt-[56.3%] relative mt-7'}>
                {/*<video controls className={'absolute w-full h-full top-0'}>*/}
                {/*  <source*/}
                {/*    src="https://drive.google.com/file/d/1V07AsWZeh8lfIe5HKo6kENqh0bgWZlp6/view"*/}
                {/*    type="video/mp4"*/}
                {/*  />*/}
                {/*</video>*/}
                <iframe
                    className={'absolute w-full h-full top-0'}
                    src="https://www.youtube.com/embed/-GhY9n3JCSk"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                ></iframe>

            </div>
            <div className={'flex flex-col items-center gap-5 pt-4'}>
                <div className={'font-extrabold uppercase'}>
                    Follow along on social media!
                </div>
                <div className={'flex w-full justify-evenly px-4'}>
                    <a
                        href={'https://www.instagram.com/tomoinc/'}
                        target={'_blank'}
                        rel="noreferrer"
                        className={'flex flex-col items-center gap-2'}
                    >
                        <InsIcon className={'size-12'}/>
                        <div className={'text-[10px]'}>@tomoinc</div>
                    </a>
                    <a
                        href={'https://x.com/tomo_social'}
                        target={'_blank'}
                        rel="noreferrer"
                        className={'flex flex-col items-center gap-2'}
                    >
                        <TwIcon className={'size-12'}/>
                        <div className={'text-[10px]'}>@tomo_social</div>
                    </a>
                    <a
                        href={'https://www.instagram.com/courtside_crypto/'}
                        target={'_blank'}
                        rel="noreferrer"
                        className={'flex flex-col items-center gap-2'}
                    >
                        <InsIcon className={'size-12'}/>
                        <div className={'text-[9px]'}>@courtside_crypto</div>
                    </a>
                    <a
                        href={'https://x.com/courtsidecrypto'}
                        target={'_blank'}
                        rel="noreferrer"
                        className={'flex flex-col items-center gap-2'}
                    >
                        <TwIcon className={'size-12'}/>
                        <div className={'text-[10px]'}>@courtsidecrypto</div>
                    </a>
                </div>
                <a
                    target={'_blank'}
                    href={'https://www.youtube.com/channel/UC1j7u7joS2BMgbYUbpCxz0w'}
                    rel="noreferrer"
                >
                    <YoutubeIcon/>
                </a>
            </div>
            <div className={'flex flex-col gap-3 w-full pt-6 px-4'}>
                <a
                    href={'https://apps.apple.com/us/app/tomo-inc/id6468010287'}
                    className={
                        'bg-tc1 dark:bg-tc1-dark w-full rounded-lg py-3 text-white dark:text-tc1 font-semibold'
                    }
                >
                    <div className={'flex items-center gap-3.5 justify-center'}>
                        <div className={'-mt-0.5'}>
                            <AppleIcon/>
                        </div>
                        <div>Download Tomo on Apple Store</div>
                    </div>
                </a>
                <a
                    href={'https://play.google.com/store/apps/details?id=tomo.app.unyx'}
                    className={
                        'bg-tc1 dark:bg-tc1-dark w-full rounded-lg py-3 text-white dark:text-tc1 font-semibold'
                    }
                >
                    <div className={'flex items-center gap-3.5 justify-center'}>
                        <div className={'-mt-0.5'}>
                            <GooglePlayIcon/>
                        </div>
                        <div>Download Tomo on Google Play</div>
                    </div>
                </a>
            </div>
        </div>
    )
}